import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'
import MaskedInput from 'react-text-mask'
import { compact } from 'lodash'
import { getValue } from '../../../utils/helpers'

const MaskFormGroup = styled.div.attrs((props) => ({
  className: 'form-group',
}))`
  position: relative;
  margin-bottom: 36px;

  input {
    height: 44px;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    color: var(--text-primary);
    background: var(--input-background);
  }

  label {
    position: absolute;
    top: 12px;
    left: 20px;
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    transform-origin: top left;
  }

  input:focus ~ label,
  input.has-value ~ label {
    transform: translate(-20px, -32px) scale(0.7);
  }
`

export const MaskField = (props) => {
  const { form, name, label, mask, onChange } = props
  const [hasValue, setHasValue] = useState(false)

  const updateHasValue = () => {
    setHasValue(!!getValue(form.getValues, name))
  }

  useEffect(updateHasValue, [name, form.getValues])

  const inputOnChange = (controlOnChange) => (event) => {
    controlOnChange(event)

    updateHasValue()

    if (onChange) {
      onChange()
    }
  }

  const id = name.toLowerCase()
  const inputClassName = compact([
    'form-control',
    props.inputClassName || '',
    hasValue && 'has-value',
  ]).join(' ')

  return (
    <MaskFormGroup>
      <Controller
        id={id}
        control={form.control}
        name={name}
        render={({ value, onChange, onBlur }) => (
          <MaskedInput
            mask={mask}
            guide={false}
            value={value || ''}
            className={inputClassName}
            onChange={inputOnChange(onChange)}
            onBlur={onBlur}
          />
        )}
      />
      <label htmlFor={id}>{label}</label>
    </MaskFormGroup>
  )
}
