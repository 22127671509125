import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'
import Autosuggest from 'react-autosuggest'
import { compact } from 'lodash'
import { getValue, getError } from '../../../utils/helpers'

const AutosuggestGroup = styled.div.attrs((props) => ({
  className: `form-group
    ${props.hasValue ? 'has-value' : ''}
    ${props.hasFocus ? 'has-focus' : ''}`,
}))`
  position: relative;
  margin-bottom: 36px;

  input {
    height: 44px;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    color: var(--text-primary);
    background: var(--input-background);
  }

  label {
    position: absolute;
    top: 12px;
    left: 20px;
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    transform-origin: top left;
  }

  &.has-focus,
  &.has-value {
    label {
      transform: translate(-20px, -32px) scale(0.7);
    }
  }

  .react-autosuggest__suggestions-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    z-index: 100;

    ul {
      padding: 4px;
      margin: 8px 0 0;
      border-radius: 6px;
      font-size: 14px;
      list-style: none;
      background: var(--input-background);
      box-shadow: 2px 6px 6px 0 rgba(40, 46, 103, 0.06);
    }

    li {
      padding: 6px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background: var(--input-background-hover);
      }
    }
  }
`

const getSuggestionFilter = (value) => {
  const valueRegex = new RegExp(value.toLowerCase(), 'i')
  return (suggestion) => suggestion !== value && suggestion.match(valueRegex)
}

export const TextSuggestField = (props) => {
  const {
    form,
    name,
    label,
    validation,
    defaultValue,
    suggestions,
    disabled,
    onChange,
  } = props

  const [hasValue, setHasValue] = useState(false)
  const [hasFocus, setHasFocus] = useState(false)

  const updateHasValue = () => {
    setHasValue(!!getValue(form.getValues, name))
  }

  const value = getValue(form.getValues, name)
  useEffect(updateHasValue, [name, value])

  const inputOnChange = (controlOnChange) => (event) => {
    controlOnChange(event.target.value || event.target.innerText)

    updateHasValue()

    if (onChange) {
      onChange(event)
    }
  }

  const [shownSuggestions, setShownSuggestions] = useState([])
  const onSuggestionsFetchRequested = (input) => {
    const value = input.value.trim()
    const suggestionFilter = getSuggestionFilter(value)
    if (suggestions && value.length > 0) {
      setShownSuggestions(suggestions.filter(suggestionFilter))
    }
  }
  const onSuggestionsClearRequested = () => {
    setShownSuggestions([])
  }

  const id = name.toLowerCase()
  const inputClassName = compact([
    'form-control',
    props.inputClassName || '',
    hasValue && 'has-value',
  ]).join(' ')

  const error = getError(form.errors, name)

  return (
    <AutosuggestGroup hasValue={hasValue} hasFocus={hasFocus}>
      <Controller
        id={id}
        control={form.control}
        name={name}
        rules={validation || {}}
        defaultValue={defaultValue || ''}
        render={({ value, onChange, onBlur }) => (
          <Autosuggest
            suggestions={shownSuggestions}
            getSuggestionValue={(suggestion) => suggestion}
            renderSuggestion={(suggestion) => <>{suggestion}</>}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={inputOnChange(onChange)}
            inputProps={{
              disabled: disabled,
              className: inputClassName,
              value: value || '',
              onChange: inputOnChange(onChange),
              onFocus: () => {
                setHasFocus(true)
              },
              onBlur: () => {
                onBlur()
                setHasFocus(false)
              },
            }}
          />
        )}
      />
      <label htmlFor={id}>{label}</label>
      {error && error.message && (
        <div className="validation-error">{error.message}</div>
      )}
    </AutosuggestGroup>
  )
}
