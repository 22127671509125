import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

export const useToast = () => {
  const [message, setMessage] = useState(null)

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage('')
      }, 3000)
    }
  }, [message])

  return [message, setMessage]
}

const StyledToast = styled.div`
  position: fixed;
  left: 50%;
  bottom: -140px;
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  padding: 26px 30px;
  margin-left: -25%;
  border-radius: 3px;
  color: white;
  background: #444;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  transition: bottom 0.35s ease-in-out;

  &.shown {
    bottom: 40px;
  }
`

export const Toast = (props) => {
  const [shownMessage, setShownMessage] = useState('')
  const shownClass = props.message ? 'shown' : ''

  useEffect(() => {
    if (props.message) {
      setShownMessage(props.message)
    }
  }, [props.message])

  return <StyledToast className={shownClass}>{shownMessage}</StyledToast>
}
