export const opportunitiesPath = () => '/opportunities'
export const opportunityPath = (id) => `/opportunities/${id}`
export const newOpportunityPath = () => '/opportunities/new'
export const editOpportunityPath = (id) => `/opportunities/${id}/edit`

export const contractsPath = () => '/agreements'
export const contractPath = (id) => `/agreements/${id}`
export const editContractPath = (id) => `/agreements/${id}/edit`
export const newContractPath = () => '/agreements/new'
export const newAppendixPath = (id) => `/agreements/${id}/new`

export const contactsPath = () => '/contacts'
export const contactPath = (id) => `/contacts/${id}`

export const contractPdfUrl = (code, filename) =>
  `${process.env.BACKEND_HOST}/connect/v1/contract/${encodeURIComponent(
    code
  )}.pdf${filename ? `?download=${filename}` : ''}`

export const appendixPdfUrl = (contractCode, appendixCode, filename) =>
  `${process.env.BACKEND_HOST}/connect/v1/contract/${encodeURIComponent(
    contractCode
  )}/${encodeURIComponent(appendixCode)}.pdf${
    filename ? `?download=${filename}` : ''
  }`

export const dealsPath = () => '/deals'

export const invoicesPath = () => '/invoices'

export const signupPath = () => '/signup'

export const logoutPath = () => '/logout'
