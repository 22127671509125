import { get } from 'lodash'
import moment from 'moment'
import { getCountryName } from './countries'

export const getValue = (getValues, name) => {
  const values = getValues()

  if (!values) {
    return undefined
  }

  if (name.indexOf('.') !== -1) {
    return get(values, name)
  } else {
    return values[name]
  }
}

export const getError = (errors, name) => {
  if (!errors) {
    return undefined
  }

  if (name.indexOf('.') !== -1) {
    return get(errors, name)
  } else {
    return errors[name]
  }
}

export const getOption = (options, value) =>
  options?.find((option) => option.value === value)

export const getCompany = (companies, name) => {
  if (!companies || !name) {
    return undefined
  }

  const canonicalName = name.trim().toLowerCase()
  return companies.find(
    (company) =>
      company.name && company.name.trim().toLowerCase() === canonicalName
  )
}

export const toQuery = (obj) =>
  Object.entries(obj)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&')

export const parseQuery = () => {
  const result = {}
  const pairs = window.location.search.substring(1).split('&')
  let pair = {}

  for (let i in pairs) {
    if (pairs[i] === '') continue

    pair = pairs[i].split('=')
    result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
  }

  return result
}

export const touchTextField = (id) => {
  const field = document.querySelector(`[id="${id}"]`)
  if (field) {
    if (field.value) {
      field.classList.add('has-value')
    } else {
      field.classList.remove('has-value')
    }
  }
}

export const touchCountryField = (name) => {
  const field = document.querySelector(`[name="${name}"]`)
  const country = getCountryName(field.value) || ''

  const getValueContainer = () =>
    field?.previousSibling?.querySelectorAll('div')[2]

  const setValue = (value) => {
    const valueContainer = getValueContainer()
    if (valueContainer) {
      valueContainer.innerText = value
    }
  }

  setValue(country)
}

export const getExtendedContractStatus = (record) => {
  if (record.terminatedAt) {
    return 'terminated'
  } else if (record.endedAt) {
    return 'ended'
  } else if (record.pausedAt) {
    return 'paused'
  } else {
    return record.status
  }
}

export const getInitials = (name) => {
  if (!name || name === 'NA') {
    return 'N/A'
  } else {
    return name
      .split(' ')
      .map((name) => name[0].toUpperCase())
      .join('')
  }
}

export const getCommissionPriceModel = (commissionModel) => {
  if (commissionModel === 'CPS') {
    return 'percentage'
  } else {
    return 'price'
  }
}

export const formatDate = (date) =>
  moment(date || undefined).format('MMMM DD, YYYY')

export const formatContractLength = (date) =>
  moment(date || undefined).format('DD/MM/YYYY')

export const formatPeriod = (from, till) =>
  moment(from || undefined).format('MMMM DD, YYYY') +
  ' — ' +
  moment(till || undefined).format('MMMM DD, YYYY')

export const formatMoney = (value, currency) =>
  value
    .toFixed(2)
    .replace('.', ',')
    .replace(/\B(?=(?=\d*,)(\d{3})+(?!\d))/g, '.') +
  ' ' +
  currency

export const formatMoneyValue = (contract) => {
  if (contract.revenueCents) {
    return `${(contract.revenueCents / 100).toFixed(2)} ${
      contract.data?.currency
    }`
  } else {
    return ''
  }
}

export const formatContractFilename = (contract) => {
  const code = contract.code.replace(/\W/g, '').toLowerCase()
  const year = new Date(contract.createdAt).getFullYear()
  return `${code}_contract${year}.pdf`
}

export const formatAppendixFilename = (contract, appendix) => {
  const contractCode = contract.code.replace(/\W/g, '').toLowerCase()
  const appendixCode = appendix.code.replace(/\W/g, '').toLowerCase()
  const year = new Date(appendix.createdAt).getFullYear()
  return `${contractCode}_${appendixCode}${year}.pdf`
}

export const clearUndefinedNestedErrors = (form, parent) => {
  if (form && form.errors && form.errors[parent]) {
    for (const key in form.errors[parent]) {
      if (form.errors[parent][key] !== undefined) {
        return
      }
    }
    form.clearErrors(parent)
  }
}
