import React from 'react'
import { Helmet } from 'react-helmet'

export const Head = (props) => {
  return (
    <Helmet>
      <link
        type="text/css"
        rel="stylesheet"
        href={`${process.env.BACKEND_HOST}/connect/v1/publisher/settings/direct.css`}
      />
    </Helmet>
  )
}
