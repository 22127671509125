import React, { useEffect } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

const DialogContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--dialog-fade);
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;

  &.shown {
    opacity: 1;
  }

  &:not(.shown) {
    opacity: 0;
    pointer-events: none;
  }

  dialog {
    position: relative;
    width: 50vw;
    min-width: 300px;
    padding: 60px 30px 30px;
    border: none;
    border-radius: 6px;
    color: var(--text-primary);
    background: white;
    box-shadow: 2px 6px 6px 0 rgba(40, 46, 103, 0.14);
  }

  .close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    padding: 0;
    border: 0;
    background: transparent;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  h3 {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .dialog-actions {
    text-align: right;
    margin-top: 50px;

    > button {
      padding: 13px 20px 11px;
      margin-left: 20px;
      border-radius: 6px;
    }
  }
`

export const Dialog = (props) => {
  const { show, toggle } = props
  const shownClass = show ? 'shown' : ''

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [show])

  return (
    <DialogContainer className={shownClass}>
      <dialog open={show}>
        <button className="close-btn" onClick={() => toggle(false)}>
          <SVG src="/images/icons/times.svg" />
        </button>
        {props.children}
      </dialog>
    </DialogContainer>
  )
}
