import styled from 'styled-components'

export const Panel = styled.div`
  padding: 50px 30px 30px;
  border: 1px solid var(--panel-border);
  border-radius: 6px;
  background: white;
  box-shadow: 2px 6px 6px 0 rgba(156, 178, 211, 0.14);
`

export const FlatPanel = styled.div`
  padding: 50px 30px 30px;
  background: white;
  box-shadow: 2px 6px 6px 0 rgba(156, 178, 211, 0.14);
`

export const NoPanelGutter = styled.div`
  margin-left: -30px;
  margin-right: -30px;
`
