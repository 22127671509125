import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'

export const headerHeight = 60

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${headerHeight}px;
  padding: 0 24px;
  color: white;
  background: var(--header-background);
  z-index: 999;

  .logo {
    svg {
      height: 24px;
      margin-right: -4px;
    }
  }

  .control {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    .control-menu {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 34px;
      padding: 5px 10px;
      margin-left: 8px;
      margin-right: 8px;
      border: 2px solid var(--header-background);
      border-radius: 6px;
      color: white;
      outline: none;
      transition: border-color 0.3s ease-in-out;

      &:hover,
      &.open {
        border-color: var(--header-background-hover);
      }

      &.open {
        svg.arrow {
          transform: rotate(180deg);
        }
      }

      span {
        margin-left: 16px;
        margin-right: 8px;
        font-family: var(--head-font);
        font-size: 16px;
        font-weight: 600;
      }
    }

    li {
      font-family: var(--head-font);
      font-size: 12px;
      font-weight: 500;
    }

    .control-menu-link {
      display: block;
      padding: 6px 15px;
      border-radius: 4px;
      color: var(--text-primary);
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;

      svg {
        margin-top: -2px;
        margin-right: 6px;
        vertical-align: middle;
      }
    }

    .connection-ring {
      position: relative;
      padding: 6px 15px;
      padding-left: 38px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 7px;
        left: 14px;
        width: 14px;
        height: 14px;
        border: 3px solid #d3d5e3;
        border-radius: 7px;
      }

      &.active {
        &:before {
          border-color: #50c1a8;
          box-shadow: 0 2px 4px 0 rgba(40, 157, 60, 0.3);
        }
      }
    }
  }
`

export const Header = (props) => {
  return (
    <header>
      <HeaderContainer>
        <div className="logo">
          <SVG src="/images/logo-light.svg" width={180} />
        </div>
      </HeaderContainer>
    </header>
  )
}
