import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getValue, getError } from '../../../utils/helpers'

const RadioFormGroup = styled.div.attrs((props) => ({
  className: 'form-group boolean',
}))`
  position: relative;
  margin-bottom: 36px;

  .custom-control {
    position: relative;
    white-space: nowrap;
  }

  .custom-control-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
  }

  .custom-control-indicator {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid var(--text-primary);
    border-radius: 4px;
    vertical-align: top;
    pointer-events: none;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -4px;
      left: 1px;
      width: 14px;
      height: 16px;
      background-image: url('/images/icons/tick.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
    }
  }

  .custom-control-input:checked + .custom-control-indicator {
    &:after {
      opacity: 1;
    }
  }

  .custom-control-indicator ~ .custom-control-label {
    display: inline-block;
    position: relative;
    top: -2px;
    padding-left: 10px;
    margin-right: 24px;
    white-space: normal;
    cursor: pointer;
  }
`

export const Radio = (props) => {
  const {
    form,
    name,
    label,
    onChange,
    validation,
    inputClassName,
    labelClassName,
  } = props

  const { register, getValues, errors } = form

  const [hasValue, setHasValue] = useState(false)

  useEffect(() => {
    setHasValue(!!getValue(getValues, name))
  }, [name, getValues])

  const id = name.toLowerCase()
  const error = getError(errors, name)

  return (
    <RadioFormGroup>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          id={id}
          className={`custom-control-input boolean ${inputClassName || ''} ${
            hasValue ? 'has-value' : ''
          }`}
          ref={register(validation)}
          name={name}
          onChange={onChange}
        />
        <div className="custom-control-indicator"></div>
        <div
          className={`custom-control-label boolean ${labelClassName || ''}`}
          dangerouslySetInnerHTML={{ __html: label }}
        />
        {error && error.type === 'required' && (
          <div className="validation-error">This value is required.</div>
        )}
      </div>
    </RadioFormGroup>
  )
}
