import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { getValue, getError } from '../../../utils/helpers'
import { countries, getCountryName } from '../../../utils/countries'

const CountryFormGroup = styled.div.attrs((props) => ({
  className: `form-group country
    ${props.menuOpen ? 'menu-open' : ''}
    ${props.hasValue ? 'has-value' : ''}
    ${props.disabled ? 'disabled' : ''}`,
}))`
  position: relative;
  margin-bottom: 36px;
  border-radius: 6px;

  .form-control {
    padding: 0;
    border: none;
    color: var(--text-primary);
    background: var(--input-background);
    cursor: pointer;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    background-image: url('/images/icons/arrow-down.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  label {
    position: absolute;
    top: 12px;
    left: 20px;
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    transform-origin: top left;
  }

  &.menu-open {
    .form-control {
      background: white;
      outline: none;
      box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
    }

    &:after {
      transform: rotate(180deg);
    }
  }

  &.menu-open,
  &.has-value {
    label {
      transform: translate(-20px, -32px) scale(0.7);
    }
  }

  &.disabled {
    .form-control {
      background: #e9ecef;
    }
  }

  [class$='-menu'] {
    background: var(--input-background);
  }

  [class$='-Option'] {
    color: var(--text-primary);
    background: transparent;
  }

  [class$='-Option']:hover {
    background: var(--input-background-hover);
  }
`

const styles = {
  control: () => ({
    height: 44,
    border: 'none',
    borderRadius: 6,
    fontSize: 14,
    color: 'inherit',
    background: 'transparent',
  }),

  valueContainer: () => ({
    height: 44,
    padding: '12px 20px',
  }),

  singleValue: () => ({
    maxWidth: 'calc(100% - 20px)',
    height: 21,
    marginBottom: -21,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),

  input: () => ({
    padding: 0,
    paddingRight: 10,
    margin: 0,
  }),

  menu: (provided) => ({
    ...provided,
    padding: 4,
    borderRadius: 6,
    fontSize: 14,
    boxShadow: '2px 6px 6px 0 rgba(40, 46, 103, 0.06)',
  }),

  menuList: (provided) => ({
    ...provided,
    maxHeight: 200,
    padding: 0,
    border: 'none',
  }),

  option: (provided) => ({
    padding: 6,
    borderRadius: 4,
    cursor: 'pointer',
  }),
}

const components = {
  IndicatorsContainer: () => null,
}

export const CountrySelect = (props) => {
  const {
    name,
    label,
    disabled,
    validation,
    onCountryChange,
    inputClassName,
    labelClassName,
    forceRender,
  } = props

  const { register, getValues, setValue, errors, clearErrors } = props.form

  const [defaultValue, setDefaultValue] = useState(undefined)
  const [hasValue, setHasValue] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const input = useRef()

  useEffect(() => {
    const value = getValue(getValues, name)
    const label = getCountryName(value)
    setHasValue(!!value)
    setDefaultValue({ label, value })
  }, [name, getValues, disabled, forceRender])

  const onMenuOpen = () => {
    setHasValue(true)
    setMenuOpen(true)
  }

  const onMenuClose = () => {
    const value = getValue(getValues, name)
    setHasValue(!!value)
    setMenuOpen(false)
  }

  const onChange = ({ value }) => {
    setValue(name, value)
    setHasValue(!!value)

    if (onCountryChange) {
      onCountryChange()
    }

    if (value && getError(errors, name)) {
      clearErrors(name)
    }
  }

  const displaySelect = defaultValue !== undefined
  const selectName = `${name}_select`
  const id = `contract_${name.replace(/\./g, '_')}`
  const error = getError(errors, name)

  return (
    <CountryFormGroup
      menuOpen={menuOpen}
      hasValue={hasValue}
      disabled={disabled}
    >
      {displaySelect && (
        <Select
          id={id}
          className={`form-control ${inputClassName || ''}`}
          name={selectName}
          styles={styles}
          components={components}
          placeholder=""
          noOptionsMessage={() => 'No results found'}
          options={countries}
          defaultValue={defaultValue}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          onChange={onChange}
          isDisabled={disabled}
        />
      )}
      <input
        type="hidden"
        name={name}
        ref={(e) => {
          register(e, validation)
          input.current = e
        }}
      />
      <label className={`string ${labelClassName || ''}`} htmlFor={id}>
        {label}
      </label>
      {error && error.type === 'required' && (
        <div className="validation-error">This value is required.</div>
      )}
    </CountryFormGroup>
  )
}
