import React, { useState, useEffect, useCallback } from 'react'

const collapseAnimationTime = 350
const collapseTimeouts = {}

export const Collapse = (props) => {
  if (props.children.length !== 2) {
    console.error('Collapse element requires exactly 2 children')
  }

  const [Header, Body] = props.children

  const openOnFocus = useCallback((event) => {
    const collapse = event.target.closest('.collapse')
    if (collapse && !collapse.classList.contains('show')) {
      const collapseToggle = collapse.parentNode.querySelector('button')
      if (collapseToggle) {
        collapseToggle.click()
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('focusin', openOnFocus)

    return () => window.removeEventListener('focusin', openOnFocus)
  }, [openOnFocus])

  return (
    <div>
      {Header}
      <div className="collapse" ref={props.control}>
        {Body}
      </div>
    </div>
  )
}

export const CollapseToggle = (props) => {
  const [isOpen, setOpen] = useState(false)

  const toggle = () => {
    const node = props.controls.current

    if (!node) {
      return
    }

    if (node.classList.contains('collapsing')) {
      return
    }

    if (collapseTimeouts[node]) {
      clearTimeout(collapseTimeouts[node])
      delete collapseTimeouts[node]
    }

    const shouldShow = !node.classList.contains('show')

    setOpen(shouldShow)

    node.classList.remove('collapse', 'show', 'collapsing')
    node.style = ''

    const childStyle = window.getComputedStyle(node.firstElementChild)
    const marginTop = parseInt(childStyle.marginTop) || 0
    const marginBottom = parseInt(childStyle.marginBottom) || 0
    const height = node.offsetHeight + marginTop + marginBottom

    if (shouldShow) {
      node.style.height = `0px`
      node.classList.add('collapsing')

      setTimeout(() => {
        node.style.height = `${height}px`
      }, 0)

      collapseTimeouts[node] = setTimeout(() => {
        node.classList.remove('collapsing')
        node.classList.add('collapse', 'show')
        node.style.height = ''
        delete collapseTimeouts[node]
      }, collapseAnimationTime)
    } else {
      node.style.height = `${height}px`
      node.classList.add('collapsing')

      setTimeout(() => {
        node.style.height = `${0}px`
      }, 0)

      collapseTimeouts[node] = setTimeout(() => {
        node.classList.remove('collapsing')
        node.classList.add('collapse')
        node.style.height = ''
        delete collapseTimeouts[node]
      }, collapseAnimationTime)
    }
  }

  return (
    <button
      type="button"
      onClick={toggle}
      className={`collapse-control ${isOpen ? 'open' : ''}`}
      tabIndex="-1"
    >
      {props.children}
    </button>
  )
}
