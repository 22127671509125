import { authToken, logout } from './auth'
import { toQuery } from '../utils/helpers'

const apiHost = process.env.BACKEND_HOST
const apiPathPrefix = '/connect/v1'

const progress = {}
const retryDelay = 1500

const apiFetch = (path, options) => {
  if (progress[path]) {
    return Promise.resolve({
      status: 0,
      data: undefined,
      message: '',
    })
  }
  progress[path] = true

  options = options || {}
  options.method = options.method || 'GET'
  options.headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Access-Token': authToken(),
  }

  return window
    .fetch(`${apiHost}${apiPathPrefix}${path}`, options)
    .then((raw) => {
      authorize(raw.status)
      progress[path] = false
      return raw.json()
    })
    .catch((error) => {
      console.error(`${path}:`, error)
      setTimeout(() => {
        progress[path] = false
      }, retryDelay)
    })
}

let unauthCount = 0
const maxUnauth = 2
const authorize = (status) => {
  if (status === 401) {
    unauthCount++
    if (unauthCount >= maxUnauth) {
      logout()
      window.location.assign('/')
    }
  } else {
    unauthCount = 0
  }
}

export const getCurrentUser = () => apiFetch(`/publisher/me`)

export const getSignupContract = (code) =>
  apiFetch(`/signup/contracts/${encodeURIComponent(code)}`)

export const signup = (publisherUser) =>
  apiFetch(`/publisher/signup/`, {
    method: 'POST',
    body: JSON.stringify({
      publisher: publisherUser,
    }),
  })

export const getContracts = (params) =>
  apiFetch(`/publisher/contracts?${toQuery(params)}`)

export const getContract = (uuid) => apiFetch(`/publisher/contracts/${uuid}`)

export const getContractFormData = (params) =>
  apiFetch(`/publisher/contracts/form`)

export const createContract = (params, options = {}) =>
  apiFetch(`/publisher/contracts`, {
    method: 'POST',
    body: JSON.stringify({
      contract: params,
      ...options,
    }),
  })

export const updateContract = (uuid, params, options = {}) =>
  apiFetch(`/publisher/contracts/${uuid}`, {
    method: 'PUT',
    body: JSON.stringify({
      contract: params,
      ...options,
    }),
  })

export const signContract = (uuid) =>
  apiFetch(`/publisher/contracts/${uuid}/sign`, {
    method: 'POST',
  })

export const declineContract = (uuid) =>
  apiFetch(`/publisher/contracts/${uuid}/decline`, {
    method: 'POST',
  })

export const createAppendix = (params) =>
  apiFetch(`/publisher/appendixes`, {
    method: 'POST',
    body: JSON.stringify(params),
  })

export const createNoteActivity = (params) =>
  apiFetch(`/publisher/activities`, {
    method: 'POST',
    body: JSON.stringify(params),
  })

export const updateDefaultContract = (params) =>
  apiFetch('/publisher/contracts/default', {
    method: 'POST',
    body: JSON.stringify({
      contract: params,
    }),
  })

export const getCompanies = (params) =>
  apiFetch(`/publisher/companies?${toQuery(params)}`)

export const getCompany = (uuid) => apiFetch(`/publisher/companies/${uuid}`)

export const getCompanyFormData = () => apiFetch(`/publisher/companies/form`)

export const createCompanyPerson = (params) =>
  apiFetch(`/publisher/companies/`, {
    method: 'POST',
    body: JSON.stringify(params),
  })

export const updateCompany = (uuid, params) =>
  apiFetch(`/publisher/companies/${uuid}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  })

export const deleteCompany = (uuid) =>
  apiFetch(`/publisher/companies/${uuid}`, {
    method: 'DELETE',
  })

export const updatePerson = (uuid, params) =>
  apiFetch(`/publisher/persons/${uuid}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  })

export const deletePerson = (uuid) =>
  apiFetch(`/publisher/persons/${uuid}`, {
    method: 'DELETE',
  })

export const getStats = (uuid, params) =>
  apiFetch(`/publisher/contracts/${uuid}/stats?${toQuery(params)}`)

export const getDeals = () => apiFetch(`/publisher/deals`)

export const getDealImages = (params) =>
  apiFetch(`/publisher/deals/images?${toQuery(params)}`)

export const getInvoices = (params) =>
  apiFetch(`/publisher/invoices?${toQuery(params)}`)

export const updateInvoice = (uuid, params) =>
  apiFetch(`/publisher/invoices/${uuid}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  })
